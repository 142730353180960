import React from "react";

const Button = ({
  children,
  className,
  onClick,
  size = "md",
  bgColour = "bg-purple-600",
  textColour = "text-white",
  hoverColour = "hover:bg-purple-700",
  focusColour = "focus:ring-purple-500",
}) => {
  let button = <></>;
  switch (size) {
    case "sm":
      button = (
        <button
          onClick={onClick}
          type="button"
          className={`inline-flex items-center rounded border border-transparent ${bgColour} 
          px-2.5 py-1.5 text-xs font-medium ${textColour} shadow-sm 
          ${hoverColour} focus:outline-none focus:ring-2 ${focusColour} focus:ring-offset-2 ${className} transition duration-150 hover:ease-in-out`}
        >
          {children}
        </button>
      );
      break;

    case "md":
      button = (
        <button
          onClick={onClick}
          type="button"
          className={`inline-flex items-center rounded-md border border-transparent ${bgColour}
           px-3 py-2 text-sm font-medium leading-4 ${textColour} shadow-sm 
          ${hoverColour} focus:outline-none focus:ring-2 ${focusColour} focus:ring-offset-2 ${className} transition duration-150 hover:ease-in-out`}
        >
          {children}
        </button>
      );
      break;

    case "lg":
      button = (
        <button
          onClick={onClick}
          type="button"
          className={`inline-flex items-center rounded-md border border-transparent ${bgColour}
           px-4 py-2 text-sm font-medium ${textColour} shadow-sm
            ${hoverColour} focus:outline-none focus:ring-2 ${focusColour} focus:ring-offset-2 ${className} transition duration-150 hover:ease-in-out`}
        >
          {children}
        </button>
      );
      break;

    case "xl":
      button = (
        <button
          onClick={onClick}
          type="button"
          className={`inline-flex items-center rounded-md border border-transparent ${bgColour}
           px-4 py-2 text-base font-medium ${textColour} shadow-sm 
           ${hoverColour} focus:outline-none focus:ring-2 ${focusColour} focus:ring-offset-2 ${className} transition duration-150 hover:ease-in-out`}
        >
          {children}
        </button>
      );
      break;

    case "xxl":
      button = (
        <button
          type="button"
          onClick={onClick}
          className={`inline-flex items-center rounded-md border border-transparent ${bgColour}
           px-6 py-3 text-2xl font-medium ${textColour} shadow-sm ${hoverColour} focus:outline-none focus:ring-2 ${focusColour} focus:ring-offset-2 ${className} transition duration-150 hover:ease-in-out`}
        >
          {children}
        </button>
      );
      break;

    case "round":
      button = (
        <button
          onClick={onClick}
          type="button"
          className={`inline-flex items-center rounded-full border border-transparent ${bgColour}
           px-2 py-2 text-base font-medium sm:px-3 sm:py-3 ${textColour} shadow-lg 
           ${hoverColour} focus:outline-none focus:ring-2 ${focusColour} focus:ring-offset-2 ${className} transition duration-150 hover:ease-in-out`}
        >
          {children}
        </button>
      );
      break;
    default:
      break;
  }
  return button;
};

export default Button;
