import React from "react";
import ScrollTransition from "../ScrollTransition";

const TeamSection = ({ people }) => {
  return (
    <div className="bg-gray-50 relative py-24 md:py-32">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-10 gap-y-20 px-6 lg:px-8 xl:grid-cols-5 z-10">
        <div className="max-w-2xl xl:col-span-2">
          <ScrollTransition>
            <h2 className="mt-16 text-2xl font-semibold text-purple-700 md:-mt-10">
              Welcome
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Get To Know Us
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-500">
              We believe every child and family is unique and benefits from
              connecting with therapists who work with parents to help their
              child make gains and embrace their unique abilities. We bring over
              30+ years of clinical experience working with children and parents
              using evidence based practice and collaboration with other
              professionals, schools, and community partners. Our commitment is
              to provide a relationship-based approach to help your child/youth
              reach their full potential.
              <br></br>
              <br></br>
              Contact us to see how we can help your child.
            </p>
          </ScrollTransition>
        </div>

        <span
          role="list"
          className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3"
        >
          {people.map((person) => (
            <ScrollTransition key={person.name}>
              <li className="flex flex-col gap-10 pt-12 sm:flex-row">
                <img
                  alt=""
                  src={person.imageUrl}
                  className="aspect-[4/5] w-52 z-10 sm:h-80 sm:my-auto flex-none shadow-xl rounded-2xl object-cover"
                />
                <div className="max-w-xl flex-auto">
                  <h3 className="text-lg font-semibold leading-7 tracking-tight text-gray-900">
                    {person.name}
                  </h3>
                  <p className="text-base leading-7 text-gray-600">
                    {person.role}
                  </p>
                  <div className="mt-6 text-base leading-6 text-gray-500">
                    {person.bio}
                  </div>
                  <div role="list" className="mt-6 flex-row sm:flex gap-x-6">
                    <a
                      href={`tel:${person.phoneNumber}`}
                      className="text-gray-500 hover:text-gray-600"
                    >
                      <div className="inline-flex content-center">
                        <span className="sr-only">Phone number</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="size-5 my-auto"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p className="ml-1.5 text-inherit">
                          {person.phoneNumber}
                        </p>
                      </div>
                    </a>
                    <a
                      href={`mailto:${person.email}`}
                      className="text-gray-500 hover:text-gray-600"
                    >
                      <div className="flex content-center">
                        <span className="sr-only">LinkedIn</span>
                        <svg
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-5 w-5 my-auto"
                        >
                          <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                          <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                        </svg>
                        <p className="ml-1.5 text-inherit">{person.email}</p>
                      </div>
                    </a>
                  </div>
                </div>
              </li>
            </ScrollTransition>
          ))}
        </span>
      </div>
    </div>
  );
};

export default TeamSection;
