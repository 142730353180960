import React, { useEffect } from "react";
import { useState } from "react";
import LandingExample from "./LandingExample";

function App() {
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    setIsShowing(true);
  }, []);

  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };

  return (
    <LandingExample isShowing={isShowing}></LandingExample>

    // <div className="bg-gray-900">
    //   <Navbar></Navbar>
    //   <div className="mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
    //     <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
    //       {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
    //       <img
    //         className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
    //         src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg"
    //         alt=""
    //       />
    //     </div>
    //   </div>
    // </div>
  );
}

export default App;
