import React from "react";
import { Disclosure, DisclosureButton } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Transition } from "@headlessui/react";
import logo from "../Assets/logo_transparent.png";
import { Link } from "react-router-dom";

function classNames(...classes) {
  // .filter(Boolean) removes any null or undefined items
  return classes.filter(Boolean).join(" ");
}

const Navbar = ({ navigation, update }) => {
  // const navRef = useRef(null);
  // const [show, setShow] = useState(true);
  // const [lastScrollY, setLastScrollY] = useState(0);

  // const controlNavbar = () => {
  //   if (typeof window !== "undefined") {
  //     if (window.scrollY > lastScrollY) {
  //       // if scroll down hide the navbar
  //       setShow(false);
  //     } else {
  //       // if scroll up show the navbar
  //       setShow(true);
  //     }

  //     // remember current page location to use in the next move
  //     setLastScrollY(window.scrollY);
  //   }
  // };

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     window.addEventListener("scroll", controlNavbar);

  //     // cleanup function
  //     return () => {
  //       window.removeEventListener("scroll", controlNavbar);
  //     };
  //   }
  // }, [lastScrollY]);

  const setCurrent = (currentIndex) => {
    for (let i = 0; i < navigation.length; i++) {
      const item = navigation[i];
      if (item.current === true) {
        updateCurrentStatus(i, false);
        updateCurrentStatus(currentIndex, true);
        return;
      }
    }
  };

  const updateCurrentStatus = (index, isCurrent) => {
    update((current) =>
      current.map((obj, ind) => {
        if (ind === index) {
          return { ...obj, current: isCurrent };
        }
        return obj;
      })
    );
  };

  const MenuLink = ({ item, index, children }) => {
    if (item.target === "_blank") {
      return (
        <a href={item.ref} target={item.target} rel="noopener noreferrer">
          {children}
        </a>
      );
    }
    return (
      <Link to={typeof item.ref === "string" && item.ref}>{children}</Link>
    );
  };

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-3 pt-3 pb-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <DisclosureButton
                  className="mt-4 inline-flex items-center justify-center rounded-md p-2
                            text-base-blue transition duration-100 
                            hover:bg-base-blue-hover hover:text-white hover:ease-in-out focus:outline-none focus:ring-2 focus:ring-inset
                            focus:ring-white"
                >
                  <span className="sr-only">Open main menu</span>

                  {open ? (
                    <XMarkIcon className="block h-7 w-7" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-7 w-7" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
              <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-between">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="pointer-events-none block h-16 w-auto lg:hidden"
                    src={logo}
                    alt="Your Company"
                  />
                  <img
                    className="pointer-events-none hidden h-14 w-auto lg:block"
                    src={logo}
                    alt="Your Company"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:mt-auto sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item, index) => (
                      <MenuLink key={item.name + index} item={item}>
                        <button
                          onClick={() => {
                            if (typeof item.ref === "string") {
                              if (item.ref === "home") {
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                  behavior: "smooth",
                                });
                              }
                              return;
                            }
                            setTimeout(() => {
                              item.ref.current.scrollIntoView({
                                behavior: "smooth",
                              });
                              setCurrent(0);
                            }, 50);
                          }}
                          className={classNames(
                            item.current
                              ? "bg-base-blue-hover text-white"
                              : "text-base-blue hover:bg-base-blue-hover hover:text-white",
                            "text-md rounded-md px-3 py-2 font-medium transition duration-100 hover:ease-in-out"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </button>
                      </MenuLink>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Transition
            show={open}
            className="overflow-hidden"
            enter="transition transition-all duration-500 ease-in"
            enterFrom="transform max-h-0 opacity-0"
            enterTo="transform max-h-screen opacity-100"
            leave="transition transition-all duration-300 ease-out"
            leaveFrom="transform max-h-screen opacity-100"
            leaveTo="transform max-h-0 opacity-0"
          >
            <Disclosure.Panel className=" sm:hidden">
              {({ close }) => (
                <div className="space-y-1 px-2 pt-2 pb-3">
                  {navigation.map((item, index) => (
                    <MenuLink item={item} index={index}>
                      <button
                        className={classNames(
                          item.current
                            ? "bg-base-blue-hover text-white"
                            : "text-base-blue hover:bg-base-blue-hover hover:text-white",
                          "block w-full rounded-md px-3 py-2 text-left text-base font-medium hover:ease-in-out"
                        )}
                        onClick={() => {
                          if (typeof item.ref !== "string") {
                            close();
                            setTimeout(() => {
                              item.ref.current.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                              });
                              setCurrent(0);
                            }, 400);
                          } else {
                            close();
                          }
                        }}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </button>
                    </MenuLink>
                  ))}
                </div>
              )}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
