import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FAQ = ({ children, options }) => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:max-w-4xl divide-y divide-gray-200">
          {children}

          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {options.map((option) => (
              <Disclosure as="div" key={option.title} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <DisclosureButton className="flex w-full items-center justify-between text-left text-gray-400">
                        <span className="flex items-center">
                          <span
                            className={classNames(
                              option.iconBackground,
                              option.iconForeground,
                              "inline-flex rounded-lg p-3 ring-2 ring-white"
                            )}
                          >
                            <FontAwesomeIcon
                              icon={option.icon}
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </span>
                          <span className="ml-4 text-xl text-gray-900">
                            {option.title}
                          </span>
                        </span>

                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </dt>

                    <DisclosurePanel
                      transition
                      className={classNames(
                        "mx-auto mt-4 py-4 px-8 rounded-lg text-left origin-top transition duration-200 ease-out data-[closed]:-translate-y-2 data-[closed]:opacity-0",
                        option.textBackground
                      )}
                    >
                      <p className="text-lg text-gray-800">
                        {option.description}
                      </p>
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
