import React from "react";
import {
  CalendarDaysIcon,
  ClipboardIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import Button from "../Buttons";

const ThreeStepAction = () => {
  const redirectLink =
    "https://embracegains.clinicmaster.com/landing?clinicId=3150&lang=en-CA";
  const titles = [
    {
      id: 1,

      title: "Book Your Intake Meeting",
      icon: "CalendarDaysIcon",
      description:
        "Intake meetings are free and are used to gather basic information, meet your therapist, and answer some questions.",
    },
    {
      id: 2,
      title: "Attend an Assessment",
      icon: "ClipboardIcon",
      description:
        "We’re able to meet with you in-person or online, whichever is best for you and your child.",
    },
    {
      id: 3,
      title: "Begin Your Therapy",
      icon: "ChatBubbleLeftRightIcon",
      description:
        "This is where you take concrete steps with your child to help them find positive gains in their life.",
    },
  ];

  const Icon = ({ icon, className }) => {
    switch (icon) {
      case "CalendarDaysIcon":
        return <CalendarDaysIcon className={className} />;
      case "ClipboardIcon":
        return <ClipboardIcon className={className} />;
      case "ChatBubbleLeftRightIcon":
        return <ChatBubbleLeftRightIcon className={className} />;
      default:
        return <ClipboardIcon />;
    }
  };
  return (
    <div className="relative bg-white py-16 sm:py-16">
      <div className="relative">
        {/* <ScrollTransition> */}
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-2xl font-semibold text-purple-700">Steps</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Getting Started
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-md gap-8 px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-3 lg:px-8">
          {titles.map((step) => (
            <div
              key={step.id}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg"
            >
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex h-16 w-16 flex-shrink-0 rounded-lg bg-purple-600 p-3">
                  <Icon
                    className="my-auto mx-auto h-10 w-10 text-white"
                    icon={step.icon}
                  />
                </div>
                <div className="mt-6 flex-1">
                  <p className="text-2xl font-semibold text-gray-900">
                    {step.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {step.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <Button
            size="xxl"
            className="mt-12 shadow-lg md:mt-16"
            onClick={() => {
              window.open(redirectLink, "_blank", "noopener, noreferrer");
            }}
          >
            Get Started
          </Button>
        </div>
        {/* </ScrollTransition> */}
      </div>
    </div>
  );
};

export default ThreeStepAction;
