import { useEffect, useRef } from "react";

const ScrollTransition = ({ children }) => {
  const ref = useRef(null);

  useEffect(() => {
    const oldRef = ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      {
        threshold: 0.1, // Adjust as needed
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (oldRef) {
        observer.unobserve(oldRef);
      }
    };
  }, []);

  return (
    <div ref={ref} className="transition-opacity duration-1000 opacity-0">
      {children}
    </div>
  );
};

export default ScrollTransition;
