import React, { useEffect, useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";

import Modal from "../Modal";

const Newsletter = () => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [confirmationEmail, setConfirmationEmail] = useState("");

  useEffect(() => {
    if (!successModalOpen) {
      setConfirmationEmail("");
    }
  }, [successModalOpen]);

  useEffect(() => {
    if (!errorModalOpen) {
      setConfirmationEmail("");
    }
  }, [errorModalOpen]);

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (email) => {
    if (!email) {
      setErrorModalOpen(true);
      return;
    }
    setConfirmationEmail(email);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "Email Submissions",
        subject: `Therapy inquiry from ${email}`,
        message: `You received a contact request from a client and they gave the following email: ${email}`,
      }),
    })
      .then(() => {
        setSuccessModalOpen(true);
      })
      .catch(() => {
        setErrorModalOpen(true);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values, actions) => {
      handleSubmit(values.email);
      actions.resetForm({
        values: {
          email: "",
        },
      });
    },
  });

  return (
    <>
      <Modal
        title={`Email Address  Submitted`}
        blurb={`Thank you for reaching out to us. We will contact you at ${confirmationEmail}`}
        buttonText="Continue"
        open={successModalOpen}
        setOpen={setSuccessModalOpen}
      />
      <Modal
        title={`Email Address Not Submitted`}
        blurb="Please reach out to us directly - admin@embracegains.com"
        buttonText="Continue"
        iconColor="text-red-600"
        iconBackgroundColor="bg-red-100"
        Icon={XMarkIcon}
        open={errorModalOpen}
        setOpen={setErrorModalOpen}
      />
      <div className="bg-white py-16 sm:pb-24">
        <div className="relative overflow-hidden sm:py-16">
          <div aria-hidden="true" className="hidden sm:block">
            <div className="absolute inset-y-0 left-0 w-1/2 rounded-r-3xl bg-gray-50" />
            <svg
              className="absolute top-8 left-1/2 -ml-3"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={392}
                fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
              />
            </svg>
          </div>
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="relative overflow-hidden rounded-2xl bg-pink-500 px-6 py-10 shadow-xl sm:px-12 sm:py-20">
              <div
                aria-hidden="true"
                className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 1463 360"
                >
                  <path
                    className="text-pink-400 text-opacity-40"
                    fill="currentColor"
                    d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                  />
                  <path
                    className="text-pink-600 text-opacity-40"
                    fill="currentColor"
                    d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                  />
                </svg>
              </div>
              <div className="relative">
                <div className="sm:text-center">
                  <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    Stay In Touch
                  </h2>
                  <p className="mx-auto mt-6 max-w-2xl text-lg text-pink-200">
                    Reach out to us today, and help your child begin to Embrace
                    Gains.
                  </p>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="mt-12 sm:mx-auto sm:flex sm:max-w-lg"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="Email Submissions"
                  />
                  <div className="min-w-0 flex-1 my-auto">
                    <label htmlFor="cta-email" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="cta-email"
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      className="block w-full rounded-md border border-transparent px-5 py-3 text-base text-gray-900 placeholder-gray-400 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-pink-500"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-3">
                    <button
                      type="submit"
                      className="block w-full rounded-md border border-transparent bg-white py-3 px-5 text-pink-700 shadow-md transition duration-150 hover:bg-gray-100 hover:ease-in-out sm:inline-block sm:w-auto"
                    >
                      Contact Us
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
